<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div class="mt-4" :class="stubbed ? 'box block' : ''" v-else>
          <h1 class="title">ONE DAY COURSES</h1>
          <b-field>
            <b-switch
                :value="sort === 'updatedAt'"
                @input="changeSortBy"
                passive-type="is-dark"
                type="is-warning"
              >
                {{ sort === 'updatedAt' ? "UPDATED" : "FINAL ASSESSMENT" }}
              </b-switch>
          </b-field>
          <h3 class="subtitle">Page: {{ page }}</h3>
          <p class="panel-tabs">
            <a :class="isAllActive" @click="getAll">All</a>
            <a :class="isSelfActive" @click="getSelf">Self</a>
            <a
              v-bind:class="isStatusActive('Open') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Open')"
              >Open</a
            >
            <a
              v-bind:class="isStatusActive('Booked') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Booked')"
              >
              Booked
            </a>
            <a
              v-bind:class="isStatusActive('To Be Booked') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('To Be Booked')"
              >
              To Be Booked
            </a>
            <a
              v-bind:class="isStatusActive('Mailed Provider') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Mailed Provider')"
              >
              Mailed Provider
            </a>
            <a
              v-bind:class="isStatusActive('Passed') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Passed')"
              >
              Passed
            </a>
            <a
              v-bind:class="isStatusActive('Retake Due') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Retake Due')"
              >
              Retake Due
            </a>
            <a
              v-bind:class="isStatusActive('Dispute') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Dispute')"
              >
              Dispute
            </a>
            <a
              v-bind:class="isStatusActive('Cancelled') ? 'is-active' : ''"
              @click="() => getOneDayCourseByStatus('Cancelled')"
              >
              Cancelled
            </a>
          </p>
          <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Location</th>
                <th>Assessment Date</th>
                <th v-if="agent.type === 'Admin'">Process by</th>
                <th v-if="!filter">Status</th>
              </tr>
            </thead>
            <tbody v-if="oneDayCourses.length">
              <tr
                @click="navigateToOrder(course.orderId)"
                v-for="course in netOnlineCourses"
                :class="course.Order.Payment &&
                 course.Order.Payment.chargeId ? 'has-background-success-light' : ''"
                :key="course.id"
              >
                <td>
                  {{ new Date(course.createdAt).toDateString() }}
                </td>
                <td>{{ course.Order.User.title }}
                  {{ course.Order.User.firstName }} {{ course.Order.User.lastName }}
                </td>
                <td>{{ course.Order.User.email || "No email" }}</td>
                <td>{{ getLocationById(course.locationId) }}</td>
                <td v-if="course.datetime">
                    {{ new Date(course.datetime).toDateString() }}
                </td>
                <td v-else>
                  No Date
                </td>
                <td v-if="agent.type === 'Admin'">
                  <span v-if="course.Order.backendAgentId">
                    <b-tag type="is-light is-primary">
                      {{ getAgentById(course.Order.backendAgentId)}}
                    </b-tag>
                  </span>
                  <span v-else>
                    <b-tag type="is-light is-primary">
                      N/A
                   </b-tag>
                  </span>
                </td>
                <td v-if="!filter">
                  {{ course.status }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!stubbed" class="is-flex-direction-row">
            <b-button
              class="is-justify-content-flex-start"
              @click="decrementPage"
              :disabled="page === 1"
              >Prev</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOneDayCoursesViewPage(1);
                  queryOneDayCourses();
                }
              "
              >1</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOneDayCoursesViewPage(5);
                  queryOneDayCourses();
                }
              "
              >5</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOneDayCoursesViewPage(10);
                  queryOneDayCourses();
                }
              "
              >10</b-button
            >
            <b-button
              class="is-justify-content-flex-start"
              @click="
                () => {
                  updateOneDayCoursesViewPage(20);
                  queryOneDayCourses();
                }
              "
              >20</b-button
            >
            <b-button class="is-justify-content-flex-end" @click="incrementPage"
              >Next</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

import { navigateToOrder, getAgentById, getLocationById } from '@/common';

export default {
  data: () => ({
    isLoading: true,
  }),
  props: {
    stubbed: Boolean,
  },
  async mounted() {
    try {
      await this.queryOneDayCourses();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      'queryOneDayCourses',
      'updateOneDayCoursesViewPage',
      'setOneDayCoursesViewFilter',
      'setOneDayCoursesViewFilterStatus',
      'setOneDayCoursesViewSort',
    ]),
    navigateToOrder,
    getAgentById,
    getLocationById,
    isStatusActive(status) {
      return this.filter === 'status' && this.status === status;
    },
    async changeSortBy(value) {
      this.isLoading = true;
      if (value) {
        this.setOneDayCoursesViewSort('updatedAt');
      } else {
        this.setOneDayCoursesViewSort('date');
      }
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.isLoading = false;
      }
    },
    async decrementPage() {
      this.updateOneDayCoursesViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateOneDayCoursesViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getAll() {
      this.setOneDayCoursesViewFilter(null);
      this.setOneDayCoursesViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getSelf() {
      this.setOneDayCoursesViewFilter('self');
      if (this.status) {
        this.setOneDayCoursesViewFilterStatus(null);
      }

      this.isLoading = true;
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getOneDayCourseByStatus(status) {
      if (this.filter !== 'status') {
        this.setOneDayCoursesViewFilter('status');
      }
      this.setOneDayCoursesViewFilterStatus(status);

      this.isLoading = true;
      try {
        await this.queryOneDayCourses();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      oneDayCourses: ({ oneDayCourseView: { oneDayCourses } }) => oneDayCourses,
      page: ({ oneDayCourseView: { page } }) => page,
      sort: ({ oneDayCourseView: { sort } }) => sort,
      status: ({ oneDayCourseView: { status } }) => status,
      filter: ({ oneDayCourseView: { filter } }) => filter,
      locations: ({ oneDayCourseDetails: { locations } }) => locations,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    netOnlineCourses() {
      if (this.stubbed) {
        return this.oneDayCourses && this.oneDayCourses.slice(0, 6);
      }
      return this.oneDayCourses;
    },
    isAllActive() {
      return this.filter !== 'self' && this.filter !== 'status'
        ? 'is-active'
        : '';
    },
    isSelfActive() {
      return this.filter === 'self' ? 'is-active' : '';
    },
  },
};
</script>
<style scoped>
table {
  cursor: pointer;
}
</style>
